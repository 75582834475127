// Here you can add other styles

.organogram-node {
  background-color: $theme-green;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.span-danger {
  background-color: rgb(196, 39, 39);
  padding: 4px;
  border-radius: 3px;
  color: white;
  width: 100%;
  display: block;
  text-align: center;
}

.span-ready {
  background-color: rgb(170, 126, 50);
  padding: 4px;
  border-radius: 3px;
  color: white;
  width: 100%;
  display: block;
  text-align: center;
}

.span-success {
  background-color: $theme-green;
  padding: 4px;
  border-radius: 4px;
  color: grey;
  width: 100%;
  display: block;
  text-align: center;
}

.span-success {
  background-color: $theme-green;
  padding: 4px;
  border-radius: 4px;
  color: white;
  width: 100%;
  display: block;
  text-align: center;
}

.no-button {
  background: none !important;
  border: none !important;
}

.table-wrapper {
  overflow-x: auto;
  min-height: 200px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.responsive-table th {
  background-color: #f2f2f2;
}

.responsive-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.no-wrap {
  white-space: nowrap;
}

.btn-nostyle {
  background: none !important;
  border: none !important;
  padding: 0 !important;
}

.mb-10 {
  margin-bottom: 20px;
}

.card-spanular {
  height: 300px;
  overflow-y: auto;
}

.score-danger {
  background-color: $theme-red;
  padding: 4px;
  border-radius: 3px;
  padding-top: 0;
  padding-bottom: 0;
  display: block;
  color: white;
  width: 36px;
  text-align: center;
}

.score-success {
  background-color: $theme-green;
  padding: 4px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 3px;
  display: block;
  color: white;
  width: 36px;
  text-align: center;
}

.card-title-60 {
  display: inline-block;
  width: 60%;
  font-size: 95%;
}

.card-title-30 {
  display: inline-block;
  width: 30%;
  font-size: 95%;
}

.card-title-80 {
  display: inline-block;
  width: 80%;
  font-size: 95%;
}

.card-title-10 {
  display: inline-block;
  width: 10%;
}

.card-title-90 {
  display: inline-block;
  width: 90%;
  font-size: 95%;
}

.card-title-100 {
  display: inline-block;
  width: 100%;
}

.plus-icon {
  color: white;
  background-color: $theme-green;
  border-radius: 1rem;
  cursor: pointer;
}

.prev-icon {
  font-size: 140%;
  color: white;
  background-color: $theme-green;
  width: 36px;
  height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 18px;
  cursor: pointer;
}

.mt-20 {
  margin-top: 20px !important;
}

.drop-popup {
  font-size: 2.4em;
  height: 1em;
  cursor: pointer;
  color: #555;
}

.ml-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bs-center {
  width: 60%;
  margin: auto;
  background-color: white;
}

.big-avatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  align-items: unset;
}

.avatar {
  align-items: unset !important;
  background-size: contain;
  align-items: unset;
}

.user-avatar {
  width: 26px !important;
  height: 26px !important;
  border-radius: 13px !important;
}

.text-shadow {
  background-color: #ddd;
  padding: 3px;
  border-radius: 3px;
  margin: 3px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  display: block;
}

.bg-theme {
  background-color: $theme-blue !important;
}

.btn-theme {
  background-color: $theme-blue !important;
  border-color: $theme-blue !important;
}

.btn-cancel {
  background-color: $theme-red !important;
  border-color: $theme-red !important;
}

.input-group-text {
  display: none;
}
