.green-gradient {
  background-color: hsla(0, 0%, 0%, 1);
  background-image: radial-gradient(
      at 33% 24%,
      hsla(26, 0%, 0%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 100% 3%, #95ff8a 0px, transparent 50%),
    radial-gradient(at 0% 100%, #ff8a9f 0px, transparent 50%);
}

.curved-gradient {
  background-color: #fcfcfc;
  opacity: 1;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #f2f2f2 30px
    ),
    repeating-linear-gradient(#d8efe255, #e3e3e3);
}

.patternGradient {
  background-image: radial-gradient(grey 0.5px, transparent 0.5px),
    radial-gradient(grey 0.5px, #f5f5f5 0.5px);
  background-size: 24px 24px;
  background-position:
    0 0,
    10px 10px;
}

.colorPattern {
  background-image: radial-gradient(rgb(255, 255, 255) 0.5px, transparent 0.5px),
    radial-gradient(rgb(255, 255, 255) 0.5px, var(--selected-color) 1px);
  background-size: 24px 24px;
  background-position:
    0 0,
    10px 10px;
}
