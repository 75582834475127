/* CSS for Ant Design customization */
:root {
  --selected-color: var(--primary-color);
  --item-text-color: rgba(255, 255, 255, 0.85);
}

/* .leftmenu .ant-menu-sub {
  background-color: rgba(0, 0, 0, 0.03) !important;
} */

/* .leftmenu .ant-menu-item-selected {
  background-color: var(--selected-color) !important;
  color: white !important;
} */


.leftmenu .ant-menu-submenu-inline .ant-menu-item,
.leftmenu .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title {
  padding-left: 30px !important;
}

.leftmenu .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-item {
  padding-left: 40px !important;
}

.leftmenu >.ant-menu-item,
.leftmenu >.ant-menu-submenu>div{
  padding-left: 1rem !important;
}

.leftmenu .ant-menu-submenu-title .ant-menu-item-icon {
  font-size: 1rem;
}


.delete-icon:hover {
  color: #d70040;
}

.edit-icon:hover {
  color: #0096ff;
}

.ant-checkbox-group {
  display: grid;
  grid-template-columns: auto auto auto;
}

@media only screen and (max-width: 990px) {
  .ant-drawer-content-wrapper {
    max-width: 300px !important;
  }
  .ant-menu {
    font-size: 12px !important;
  }
}

.ant-table-content > table > tbody > tr:nth-of-type(odd) > td {
  background-color: #f9f9f9;
}

.rightmenu .ant-menu-sub {
  background-color: rgba(0, 0, 0, 0.03) !important;
  color: black !important;
}

.rightmenu .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.03) !important;
  color: black !important;
}

.rightmenu .ant-menu-item {
  padding-left: 20px !important;
  white-space: pre-wrap;
  line-height: 1.5 !important;
  color: black !important;
}

.rightmenu .ant-menu-submenu-title {
  padding-left: 20px !important;
  white-space: pre-wrap;
  line-height: 1.5 !important;
  color: black !important;
}

.rightmenu .ant-menu-item .ant-menu-item-icon {
  font-size: large;
}

.rightmenu .ant-menu-submenu-title .ant-menu-item-icon {
  font-size: large;
}

:where(.css-dev-only-do-not-override-1by05qx).ant-btn-primary {
  box-shadow: none !important;
}
