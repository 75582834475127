/* TEXT TRANSFORM */
.capitalize {
  text-transform: capitalize;
}

a {
  text-decoration: none !important;
}

.sidebar-backdrop {
  z-index: 150 !important;
}

.sidebar {
  z-index: 200 !important;
}
.breadcrumb-item {
  font-size: inherit !important;
  color: var(--main-bg-color) !important;
  outline: none !important;
  border: none !important;
  background: none !important;
}
.breadcrumb-item:hover {
  text-decoration: underline !important;
  background: none !important;
  color: black !important;
}

.mapboxgl-ctrl {
  display: none !important;
}

.link-text {
  color: var(--selected-color) !important;
}
.link-text:hover {
  text-decoration: underline !important;
}

.page-link {
  color: var(--selected-color) !important;
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--cui-pagination-active-color) !important;
  background-color: var(--selected-color) !important;
  border-color: var(--selected-color) !important;
}

.link-with-icon {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--selected-color) !important;
}

.link-text {
  color: var(--selected-color) !important;
}

.link-text:hover {
  text-decoration: none;
}

/* Phone Input */
.phone-input-custom {
  width: 100% !important;
  font-size: 14px !important;
  padding-left: 48px !important;
}

.phone-input-custom.form-control {
  padding-left: 48px !important;
}

.react-tel-input .flag-dropdown {
  background-color: white !important;
}
.react-tel-input .flag-dropdown:hover {
  background-color: var(--cui-tertiary-bg) !important;
}

.react-tel-input .country-list .search {
  display: flex;
  padding: 10px !important;
}
.react-tel-input .country-list .search-box {
  /* width: 85%; */
  flex: 1;
  margin-left: 0;
}
.react-tel-input .country-list .country:hover {
  background-color: var(--cui-tertiary-bg) !important;
}


@media print {
  /* Hide elements not needed in print */
  .breadcrumb-item,
  button,
  .ant-pagination, /* Adjust class names based on your UI library */
  .no-print {
    display: none !important;
  }
  
  /* Ensure the table uses the full page width */
  table {
    width: 100% !important;
    overflow: visible !important;
  }
  
  /* Adjust font sizes for better print readability */
  body {
    font-size: 10pt;
    background-color: white !important;
  }
  
  /* Remove any backgrounds/shadows that might not print well */
  .ant-table,
  .ant-card {
    box-shadow: none !important;
    background-color: transparent !important;
  }
  /* Remove pagination */
  .panel-footer {
    display: none !important;
  }
  
  /* Add page breaks where needed */
  .page-break-after {
    page-break-after: always;
  }
}

.align-center {
  align-items: center;
}
