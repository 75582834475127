:root {
  --primary-color: #3a92c9 !important;
  --success-color: #2e7d32 !important;
}
.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
}
@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}

/* FLEX */
.flex {
  display: flex;
}

.flexGrow {
  flex-grow: 1;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexNullCenter {
  display: flex;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexRow {
  display: flex;
  flex-direction: row;
}

.spaceCenter {
  justify-content: space-between;
}

/* GAPS */
.gap5 {
  gap: 5px;
}

.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 20px;
}
.gap30 {
  gap: 30px;
}

/* POINTERS */
.pointer {
  cursor: pointer;
}

/* HELPERS */
.x100 {
  width: 100%;
}
.h100 {
  height: 100%;
}

.vh100 {
  height: 100vh;
}

.textCenter {
  text-align: center;
}

.overflow {
  overflow: hidden;
}
.animate {
  transition: 0.3s ease;
}

/* LINKS */
.lightLink {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

/* FONT SIZE */
.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font14 {
  font-size: 14px;
}

.font20 {
  font-size: 20px;
}

.font22 {
  font-size: 22px;
}

.font25 {
  font-size: 25px;
}

/* TEXT COLOR */
.white {
  color: white;
}
.red {
  color: red;
}
.green {
  color: green;
}

.black {
  color: black;
}

.mainColor {
  color: var(--selected-color);
}

.primaryColor {
  color: var(--selected-color);
}

.blackColor {
  color: #000;
}

.greyColor {
  color: grey;
}

/* FONT WEIGHT */
.light {
  font-weight: 200;
}
.extraBold {
  font-weight: 900;
}
.bold {
  font-weight: bold;
}

/* BACKGROUNDS */
.lightBg {
  background-color: #f2f2f2;
}

.whiteBg {
  background-color: white;
}

.mainBg {
  background-color: var(--selected-color);
}

/* RADIUS */
.radius10 {
  border-radius: 10px;
}
.radius20 {
  border-radius: 20px;
}
.radius30 {
  border-radius: 30px;
}

/* PADDING */
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

/* INPUTS */

input {
  outline: none !important;
}
.form-control {
  border-radius: 0.25rem !important;
  padding: 0.4rem 0.5rem !important;
}

.form-control:focus {
  border-color: #78ffac;
  box-shadow: none !important;
}

/* Dropdown Button */
.dropbtn {
  color: white;
  padding: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 62px;
  background-color: #303540;
  min-width: 160px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: var(--selected-color);
  color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
