/* MARGINS */

/* MARGIN BOTTOM */
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}
